/**
 * Every route becomes a chunk, loaded only when used.
 * Reduces size of initial App load.
 */
const routes = [
  {
    name: "login",
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/modules/login/page.vue"),
    title: "modules.login.documentTitle",
    layout: "auth-layout",
    isPublic: true
  },
  {
    name: "forgotPassword",
    path: "/forgot",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "@/modules/forgot-password/page.vue"
      ),
    title: "modules.forgotPassword.documentTitle",
    layout: "auth-layout",
    isPublic: true
  },
  {
    name: "resetPassword",
    path: "/reset/:user_token",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "@/modules/reset-password/page.vue"
      ),
    title: "modules.resetPassword.documentTitle",
    layout: "auth-layout",
    isPublic: true
  },
  {
    name: "otpVerification",
    path: "/otp-verification",
    component: () =>
      import(
        /* webpackChunkName: "otpVerification" */ "@/modules/otp-verification/page.vue"
      ),
    title: "modules.otpVerification.documentTitle",
    layout: "auth-layout",
    isPublic: true
  },
  // {
  //   name: "otpReminder",
  //   path: "/otp-reminder",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "otpReminder" */ "@/modules/otp-reminder/page.vue"
  //     ),
  //   title: "modules.otpReminder.documentTitle",
  //   layout: "auth-layout",
  //   isPublic: false
  // },
  {
    name: "home",
    path: "/",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/modules/home/page.vue"),
    title: "app.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "environments",
    path: "/config/environments",
    component: () =>
      import(
        /* webpackChunkName: "environments" */ "@/modules/environments/page.vue"
      ),
    title: "modules.environments.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "users",
    path: "/config/users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/modules/users/page.vue"),
    title: "modules.users.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "devices",
    path: "/config/devices",
    component: () =>
      import(/* webpackChunkName: "devices" */ "@/modules/devices/page.vue"),
    title: "modules.devices.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "dataPath",
    path: "/config/data-path",
    component: () =>
      import(/* webpackChunkName: "dataPath" */ "@/modules/data-path/page.vue"),
    title: "modules.dataPath.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "versionsManagement",
    path: "/admin/versions-management",
    component: () =>
      import(
        /* webpackChunkName: "versionsManagement" */ "@/modules/admin/versions-management/page.vue"
      ),
    title: "modules.admin.versionManagement.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "thresholds",
    path: "/admin/thresholds",
    component: () =>
      import(
        /* webpackChunkName: "thresholds" */ "@/modules/admin/thresholds/page.vue"
      ),
    title: "modules.admin.thresholds.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "usageMonitoring",
    path: "/admin/usage-monitoring/:id?",
    component: () =>
      import(
        /* webpackChunkName: "usageMonitoring" */ "@/modules/admin/usage-monitoring/page.vue"
      ),
    title: "modules.admin.usageMonitoring.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "auditLog",
    path: "/logs/audit-log",
    component: () =>
      import(
        /* webpackChunkName: "auditLog" */ "@/modules/logs/audit-log/page.vue"
      ),
    title: "modules.logs.auditLog.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "systemLog",
    path: "/logs/system-log",
    component: () =>
      import(
        /* webpackChunkName: "systemLog" */ "@/modules/logs/system-log/page.vue"
      ),
    title: "modules.logs.systemLog.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "messagingLog",
    path: "/logs/messaging-log",
    component: () =>
      import(
        /* webpackChunkName: "messagingLog" */ "@/modules/logs/messaging-log/page.vue"
      ),
    title: "modules.logs.messagingLog.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "sessionExpired",
    path: "/session-expired",
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ "@/modules/session-expired/page.vue"
      ),
    title: "modules.sessionExpired.documentTitle",
    layout: "auth-layout",
    isPublic: true
  },
  {
    name: "heatSight",
    path: "/services/heatsight",
    component: () => import("@/modules/heatsight/page.vue"),
    title: "modules.heatSight.documentTitle",
    layout: "app-layout",
    isPublic: false
  },
  {
    name: "notFound",
    path: "*",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/modules/not-found/page.vue"),
    title: "modules.notFound.documentTitle",
    layout: "auth-layout",
    isPublic: true
  }
];

export default routes;
