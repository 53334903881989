import store from "@/store";
import i18n from "@/plugins/i18n";

export function currentEnvironmentId() {
  return store.state.login.user.environment_id;
}

export function showEnvironmentUpdateSuccessMessage() {
  let environmentUpdateSuccessMessage = {
    color: "success",
    text: i18n.t("modules.environments.updateSuccess"),
    id: "update-success"
  };
  store.dispatch("snackbar/setMessage", environmentUpdateSuccessMessage, {
    root: true
  });
}

export default {
  currentEnvironmentId: currentEnvironmentId
};
