import service from "./service";
import { getField, updateField } from "vuex-map-fields";

const defaults = {
  clock: {
    last_recvd_remote_time: null,
    last_remote_to_local_time: null
  },
  versionsDialog: {
    dialog: false,
    versions: {
      official: null,
      internal: {
        analyzer: null,
        auth: null,
        ca: null,
        config: null,
        monitoring: null
      }
    }
  },
  settings: {
    settingsDialog: false,
    configureTwoFA: {
      qr: "",
      step: 1,
      setupStatus: 1,
      otpUri: ""
    }
  }
};

const module = {
  namespaced: true,
  state: { ...defaults },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadClock(state, data) {
      state.clock.last_recvd_remote_time = new Date(data).getTime();
      state.clock.last_remote_to_local_time = new Date().getTime();
    },
    fillVersions(state, data) {
      state.versionsDialog.versions = {
        official: data.versions.official,
        internal: {
          analyzer: data.versions.internal.analyzer,
          auth: data.versions.internal.auth,
          ca: data.versions.internal.ca,
          config: data.versions.internal.config,
          monitoring: data.versions.internal.monitoring
        }
      };
    },
    toggleVersionsDialog(state, data) {
      state.versionsDialog.dialog = data;
    },
    loadSetupTwoFA(state, data) {
      state.settings.configureTwoFA.setupStatus = data.setup_status;
      state.settings.configureTwoFA.otpUri = data?.totp_provisioning_uri;
    },
    updateTwoFAStates(state, data) {
      state.settings.configureTwoFA.setupStatus = data.setupStatus;
      state.settings.configureTwoFA.step = data.step;
    }
  },
  actions: {
    fetchServerClock({ commit }) {
      return service.fetchServerTime().then(response => {
        let data = response.data.server_clock;
        commit("loadClock", data);
      });
    },
    fetchVersions({ commit }) {
      return service.fetchVersions().then(response => {
        let data = response.data;
        commit("fillVersions", data);
      });
    },
    setupTwoFA({ commit }) {
      return service.setupTwoFA().then(response => {
        commit("loadSetupTwoFA", response.data);
      });
    },
    activateTwoFA({ commit }, payload) {
      return service.activateTwoFA(payload).then(response => {
        let data = response.data;
        data.totp_provisioning_uri = null;
        commit("loadSetupTwoFA", data);
      });
    },
    deleteTwoFA(context, payload) {
      return service.deleteTwoFA(payload);
    }
  }
};

export default module;
