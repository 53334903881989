const SatcomStateEnum = Object.freeze({
  DISABLED: 1,
  MISCONFIGURED: 2,
  CONNECTED: 3,
  TRANSMITTED: 4
});

const SatcomNetworkEnum = Object.freeze({
  IRIDIUM: 1
});

const TwoFAStatusEnum = Object.freeze({
  INACTIVE: 1,
  PENDING: 2,
  ACTIVE: 3
});

const TwoFAStateEnum = Object.freeze({
  NOT_LOGGED_IN: 0,
  NOT_VERIFIED: 1,
  LOGGED_IN: 2
});

const ErrorCodeEnum = Object.freeze({
  MISSING_VALUES: 1,
  BAD_QUERY_PARAMETER: 3,
  INVALID_TOKEN: 5,
  INVALID_ID: 6,
  ENV_NAME_EXISTS: 20,
  USERNAME_ALREADY_EXISTS: 30,
  EMAIL_ALREADY_EXISTS: 31,
  PASSWORD_NOT_VALID: 32,
  TWO_FACTOR_AUTH_ALREADY_ACTIVE: 140,
  TWO_FACTOR_AUTH_INVALID_OTP: 142,
  TWO_FACTOR_AUTH_NOT_INITIATED: 143,
  TWO_FACTOR_AUTH_NOT_ACTIVE: 144,
  TWO_FACTOR_AUTH_DEACTIVATE_FAIL: 145
});

const StatusCodeEnum = Object.freeze({
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404
});

export {
  SatcomStateEnum,
  SatcomNetworkEnum,
  TwoFAStatusEnum,
  TwoFAStateEnum,
  ErrorCodeEnum,
  StatusCodeEnum
};
