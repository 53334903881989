import service from "./service";
import i18n from "@/plugins/i18n";
import { getDeepCopy } from "@/utils";
import { getField, updateField } from "vuex-map-fields";
import { getPayloadFromData } from "./util";

const defaults = {
  devices: [],
  halo_data_links_availability: [],
  halo_data_links_info: [],
  halo_system_report: [],
  search: "",
  selected: [],
  dialog: false,
  importDialog: false,
  formTitle: i18n.t("modules.devices.add"),
  editedIndex: -1,
  editedItem: {
    name: "",
    pairing_id: "",
    ipAddresses: null,
    fromPort: 1024,
    toPort: 49151,
    isEnabled: true,
    isServer: false,
    isAutoUpgrade: false,
    allowedDays: [],
    allowedHours: [],
    fixedUpgradeVersion: null,
    timeout: null,
    isAllowedUpgradeWhenBusy: false
  },
  defaultItem: {
    name: "",
    pairing_id: "",
    ipAddresses: null,
    fromPort: 1024,
    toPort: 49151,
    isEnabled: true,
    isServer: false,
    isAutoUpgrade: true,
    allowedDays: [1, 2, 3, 4, 5, 6, 7],
    allowedHours: [...Array(24).keys()],
    fixedUpgradeVersion: null,
    timeout: null,
    isAllowedUpgradeWhenBusy: false
  },
  rfRecordings: {
    openDialog: false,
    device_id: null,
    allRecordings: []
  },
  devicesState: []
};

const module = {
  namespaced: true,

  state: { ...defaults },

  getters: {
    getField
  },
  mutations: {
    updateField,
    load(state, data) {
      state.devices = data;
    },
    loadHaloDataLinksAvailability(state, data) {
      state.halo_data_links_availability = data;
    },
    loadDevicesStatus(state, data) {
      let devices = Object.keys(data);
      devices.map(device => {
        let deviceIndex = state.devices.findIndex(item => item.id === device);
        if (deviceIndex > -1) {
          state.devices[deviceIndex].halo_data_links_info = data[device]
            .halo_data_links_info
            ? data[device].halo_data_links_info
            : null;
          state.devices[deviceIndex].halo_system_report = data[device]
            .halo_system_report
            ? data[device].halo_system_report
            : null;
        }
      });
      // state.halo_data_links_info = data;
    },
    loadHaloSystemReport(state, data) {
      state.halo_system_report = data;
    },
    addItem(state, data) {
      state.devices.push(data);
    },
    updateItem(state, item) {
      Object.assign(state.devices[state.editedIndex], item);
    },
    toggleDialog(state, payload) {
      state.dialog = payload;
    },
    updateImportDialog(state, payload) {
      state.importDialog = payload;
    },
    fillForm(state, payload) {
      state.editedItem = Object.assign({}, payload);
    },
    clearForm(state) {
      state.editedItem = Object.assign({}, state.defaultItem);
    },
    updateSelected(state, value) {
      state.selected = value;
    },
    updateIndex(state, payload) {
      state.editedIndex = payload;
    },
    updateFormTitle(state, type) {
      if (type === 1) {
        state.formTitle = i18n.t("modules.devices.add");
      } else {
        state.formTitle = i18n.t("modules.devices.edit");
      }
    },
    updateSearch(state, payload) {
      state.search = payload;
    },
    removeItem(state, id) {
      let index = state.devices.findIndex(x => x.id === id);
      state.devices.splice(index, 1);
    },
    removeIP(state, index) {
      state.editedItem.ipAddresses.splice(index, 1);
      state.editedItem.ipAddresses = [...state.editedItem.ipAddresses];
    },
    loadRfRecordings(state, payload) {
      state.rfRecordings.allRecordings.push(...payload);
    },
    updateRfRecordingOpenDialog(state, payload) {
      state.rfRecordings.openDialog = payload;
    },
    updateRfRecordingDeviceId(state, payload) {
      state.rfRecordings.device_id = payload;
    },
    loadDevicesState(state, devices_state) {
      state.devicesState = devices_state;
    },
    clearAllRfRecordings(state) {
      state.rfRecordings.allRecordings = [];
    }
  },

  actions: {
    fetchAll({ commit }) {
      return service.fetchDevices().then(response => {
        let data = response.data.devices;
        commit("load", data);
      });
    },

    fetchHaloDataLinksAvailability({ commit }) {
      let deviceIds = [];
      return service
        .fetchHaloDataLinksAvailability(deviceIds)
        .then(response => {
          let data = response.data.halo_data_links_availability;
          commit("loadHaloDataLinksAvailability", data);
        });
    },

    fetchHaloDataLinksInfo(context, payload) {
      return service.fetchHaloDataLinksInfo(payload.devices);
    },

    fetchDevicesStatus(context, payload) {
      return service.fetchDevicesStatus(payload.devices);
    },

    add({ dispatch }, data) {
      const payload = getPayloadFromData(data);
      return service.addDevice(payload).then(() => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.devices.addSuccess"),
              id: "add-success"
            },
            { root: true }
          );
        });
      });
    },

    importDevices(context, data) {
      return service.importDevices(data);
    },

    update({ dispatch }, data) {
      const payload = getPayloadFromData(data);
      const deviceId = data.id;
      return service.updateDevice(deviceId, payload).then(() => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.devices.updateSuccess"),
              id: "update-success"
            },
            { root: true }
          );
        });
      });
    },

    delete({ dispatch }, id) {
      return service.deleteDevice(id).then(() => {
        dispatch("fetchAll").then(() => {
          // commit("removeItem", id);
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.devices.deleteSuccess"),
              id: "delete-success"
            },
            { root: true }
          );
        });
      });
    },

    toggleDialog({ commit }, payload) {
      commit("toggleDialog", payload);
    },

    editItem({ state, commit }, payload) {
      let data = getDeepCopy(state.defaultItem);

      Object.assign(data, {
        name: payload.name,
        pairing_id: payload.pairing_id,
        ipAddresses: payload.ip_addresses || [],
        fromPort: payload.ports ? payload.ports.from : data.fromPort,
        toPort: payload.ports ? payload.ports.to : data.toPort,
        isEnabled: payload.is_enabled,
        type: payload.type,
        isServer: payload.is_server
      });

      let upgradePolicy = payload.upgrade_policy;

      if (upgradePolicy) {
        Object.assign(data, {
          isAutoUpgrade: upgradePolicy.is_auto_upgrade_enabled,
          allowedDays: upgradePolicy.allowed_days,
          allowedHours: upgradePolicy.allowed_hours,
          fixedUpgradeVersion:
            upgradePolicy.fixed_version || data.fixedUpgradeVersion,
          timeout: upgradePolicy.idle_timeout_in_minutes,
          isAllowedUpgradeWhenBusy: !isNaN(
            upgradePolicy.idle_timeout_in_minutes
          )
        });
      } else {
        data.isAutoUpgrade = !payload.is_server;
      }

      commit("fillForm", data);
    },

    clearForm({ commit }) {
      commit("clearForm");
    },
    fetchRfRecordings({ commit }, params) {
      return service.fetchRfRecordings(params).then(response => {
        commit("loadRfRecordings", response.data.rf_recordings);
      });
    },
    downloadRfRecordings(context, data) {
      return service.downloadRfRecordings(data);
    },
    checkRfRecordingUrlToFile(context, url) {
      return service.checkRfRecordingUrlToFile(url);
    },
    fetchDevicesState({ commit }) {
      return service.fetchDevicesState().then(response => {
        commit("loadDevicesState", response.data.devices_state);
      });
    },
    clearAllRfRecordings({ commit }) {
      commit("clearAllRfRecordings");
    }
  }
};

export default module;
